@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

*,
::before,
::after {
    box-sizing: border-box;

}
body {
    margin: 0
}

.landingContainer {
    display: flex;
    /* flex: 1; */
    justify-content: center;
    align-items: center;
    background-color: #FAF9F6;
    height: 100%;
    width: 100%;
}

.mainContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    
    
    background: rgb(255,255,255);
background: -moz-linear-gradient(144deg, rgba(255,255,255,1) 31%, rgba(67,183,255,1) 100%);
background: -webkit-linear-gradient(144deg, rgba(255,255,255,1) 31%, rgba(67,183,255,1) 100%);
background: linear-gradient(144deg, rgba(255,255,255,1) 31%, rgba(67,183,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#43b7ff",GradientType=1);
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

.headerText {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
    opacity: 0.7;
    font-size: 25px;
}

.subHeaderText {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #fff;
    opacity: 0.4;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    margin-top: 5px;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}


.linkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


}

.links {
    font-size: 14px;
    text-decoration: none;
    color: #000;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    padding: 5px;
}

.text {
    font-size: 10px;
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.textContainer {
    width: 80%;
    height: 100%;
    overflow: scroll;
    padding: 40px;
}